/*
 * @Author: your name
 * @Date: 2021-03-08 11:27:02
 * @LastEditTime: 2021-03-12 16:06:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\api\patient.js
 */
import { Http } from '@/utils/http'
import {combineUrl} from '../utils/index'
const URL_PREFIX = '/user'
export class PatientModel extends Http {
  getPatientList(params){
    return this.post(combineUrl(URL_PREFIX, '/patientinfo/patientAll'), params,{isFormData:true})
  }
  getRelation(params){
    //const url = combineUrl(URL_PREFIX, '/constants/jtgxlist',{fzdm:'jtgx'})
    //return this.post(url)
    return this.post(combineUrl(URL_PREFIX, '/constants/jtgxlist'), params,{isFormData:true})
  }
  add(params){
    return this.post(combineUrl(URL_PREFIX,'/patientinfo/insert'),params)
  }
  //编辑就诊人
  edit(params){
    return this.post(combineUrl(URL_PREFIX,'/patientinfo/edit'),params)
  }
  getPatientListDetail(params){
    return this.post(combineUrl(URL_PREFIX, '/patientinfo/patientDetail'), params,{isFormData:true})
  }
  delete(params){
    return this.post(combineUrl(URL_PREFIX, '/patientinfo/delete'), params,{isFormData:true})
  }
  jcReportList(params){
    return this.post(combineUrl(URL_PREFIX, '/patientinfo/queryJcReportList'), params)
  }
  jcReportDetail(params){
    return this.post(combineUrl(URL_PREFIX,'/patientinfo/queryJcReportDetail'),params,{isFormData:true})
  }
  jyReportList(params) {
    return this.post(combineUrl(URL_PREFIX, '/patientinfo/queryJyReportList'), params)
  }
  jyReportDetail(params) {
    return this.post(combineUrl(URL_PREFIX, '/patientinfo/queryJyReportDetail'), params,{isFormData:true})
  }
}
export const patientModel = new PatientModel()
