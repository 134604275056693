/*
 * @Author: 张阳帅
 * @Date: 2024-09-23 14:29:23
 * @LastEditTime: 2024-10-15 11:05:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\api\gjcApi.js
 */
import { Http } from '@/utils/http'

export class GjcModel extends Http {
    /**
    * 查询可挂号的科室信息
    * hospCode 机构代码
    */
    getQueryRegisterDept(params) {
        return this.get(`/user/gjcScheduling/queryRegisterDept`, params)
    }

    /**
    * 查询某个区间的可挂号数据
    * hospCode 机构代码
    */
    getQueryRegisterData(params) {
        return this.get(`/user/gjcScheduling/queryRegisterData`, params)
    }

    /**
    * 线下预约挂号、当天挂号
    * params
    */
    getCreateRegisterData(params) {
        return this.post(`/user/gjcAppointRecord/createRegister`, params)
    }

    /**
    * 查询线下预约挂号记录列表（分页）
    * params
    */
    getQueryAppointRecordPageData(params) {
        return this.post(`/user/gjcAppointRecord/queryAppointRecordPage`, params)
    }

    /**
    * 线下挂号预约取消
    * params
    */
    getCancelRegisterData(params) {
        return this.post(`/user/gjcAppointRecord/cancelRegister`, params)
    }
    /**
    * 查询线下预约挂号记录详情
    */
    getQueryAppointRecordDetailData(id) {
        return this.get(`/user/gjcAppointRecord/queryAppointRecordDetail/${id}`)
    }

    /**
    * 创建预约挂号缴费订单
    */
    getAppointOrderCreateData(id) {
        return this.get(`/user/gjcAppointOrder/create/${id}`)
    }
    /**
    * 微信退款
    */
    getWXRefundData(params) {
        return this.post(`/wxrefund/start`, params)
    }
    /**
    * 医保退款接口
    */
    getYbRefundData(params) {
        return this.post(`/user/ybPay/ybRefund`, params)
    }
    /**
    * 首页就诊人数据
    */
    getHomePatientListData(params) {
        return this.post(`/user/gjcPatientInfo/gethomePage`, params)
    }
    /**
    * 查询就诊人信息列表
    */
    getPatientListData(params) {
        return this.post(`/user/gjcPatientInfo/getPatientList`, params)
    }
    /**
    * 设置默认就诊人
    */
    getSetDefaultPatientData(id) {
        return this.get(`/user/gjcPatientInfo/setDefaultPatient/${id}`)
    }

    /**
    * 设置默认就诊卡
    */
    getSetDefaultPatientCardData(id, cardId) {
        return this.get(`/user/gjcPatientInfo/setDefaultPatient/${id}/${cardId}`)
    }

    /**
    * 编辑就诊人信息
    */
    getUpdatePatientData(params) {
        return this.post(`/user/gjcPatientInfo/update`, params)
    }

    /**
    * 查询就诊人信息详情
    */
    getPatientDetailData(id) {
        return this.get(`/user/gjcPatientInfo/detail/${id}`)
    }

    /**
    * 查询His就诊人信息
    */
    getHisPatientData(params) {
        return this.post(`/user/gjcPatientInfo/getHisPatient`, params)
    }

    /**
    * 删除就诊人
    */
    getDeletePatientData(id) {
        return this.get(`/user/gjcPatientInfo/delete/${id}`)
    }

    /**
    * 新加就诊人
    */
    getAddPatientData(params) {
        return this.post(`/user/gjcPatientInfo/add`, params)
    }
    /**
    * 建档就诊人-发送验证码
    */
    sendCaptchaGjc(phone) {
        return this.post(`/captcha/send_captcha_gjc/${phone}`)
    }
    /**
    * 建档就诊人-校验验证码
    */
    verifySendCaptchaGjc(params) {
        return this.post('/captcha/verify_send_captcha_gjc', params)
    }
    /**
    * 查询产生的最新消息
    */
    getQueryNewestMsgData(params) {
        return this.post(`/user/gjcRemindMsg/queryNewestMsg`, params)
    }
    /**
    * 查询导医提醒列表(分页)
    */
    getMsgPageListData(params) {
        return this.post(`/user/gjcRemindMsg/getPageList`, params)
    }
    /**
    * 设置全部已读
    */
    getAllMsgReadData() {
        return this.post(`/user/gjcRemindMsg/setAllMsgRead`)
    }
    /**
    * 查询导医提醒详情
    */
    getMsgDetailData(id) {
        return this.get(`/user/gjcRemindMsg/detail/${id}`)
    }
    /**
    * 获取查看报告模式
    */
    getReportModeData() {
        return this.get(`/user/gjcReport/getReportMode`)
    }
    /**
    * 数据中心报告地址
    */
    getDataCenterReportData(params) {
        return this.post(`/user/gjcReport/dataCenter/report`, params)
    }

    /**
    * 签到排队详情
    */
    getSignInQueueDetailData(params) {
        return this.post(`/user/gjcSignInQueue/detail`, params)
    }
    /**
    * 签到排队
    */
    getSignInQueueSignInData(params) {
        return this.post(`/user/gjcSignInQueue/signIn`, params, { isFormData: true })
    }
    /**
    * 签到排队
    */
    getSignInQueueListData(params) {
        return this.post(`/user/gjcSignInQueue/queueList`, params, { isFormData: true })
    }
    /**
    * 签到排队
    */
    getSignInLocationData(params) {
        return this.post(`/user/gjcSignInQueue/inLocation`, params)
    }

    //门诊缴费列表
    getOnlinePayListData(params) {
        return this.post(`/user/gjcOnlinePay/list`, params)
    }
    //门诊合计费用计算
    feeCalculation(params) {
        return this.post(`/user/gjcOnlinePay/feeCalculation`, params)
    }
    //创建门诊缴费订单
    addMzOrderInfo(params) {
        return this.post(`/user/gjcOnlinePay/addMzOrderInfo`, params)
    }
    //门诊缴费订单详情
    mzPayDetail(orderId) {
        return this.get(`/user/gjcOrderInfo/payDetail/${orderId}`)
    }
    //挂号缴费列表
    appointOrderList(params) {
        return this.post(`/user/gjcAppointOrder/appointOrderList`, params)
    }
    //住院缴费列表
    gjcZyOnLinePay(params) {
        return this.post(`/user/gjcZyOnLinePay/list`, params)
    }
    //创建住院押金补缴订单
    gjcZyOnLinePayCreateOrder(params) {
        return this.post(`/user/gjcZyOnLinePay/create/order`, params)
    }

    /**
    * 住院记录查询
    */
    getZyOnlinePayListData(params) {
        return this.post(`/user/gjcZyOnLinePay/list`, params)
    }
    /**
    * 处方分页列表
    */
    getPrescriptionListData(params) {
        return this.post(`/user/gjcPrescription/pageList`, params)
    }
    /**
    * 处方详情
    */
    getPrescriptionDetailData(params) {
        return this.post(`/user/gjcPrescription/detail`, params, { isFormData: true })
    }
    /**
    * 费用明细--线上费用查询（分页）
    */
    /**
     * 获取订单列表信息
     * @param {Object} params - 请求参数
     * @returns {Promise} - 返回订单列表信息的 Promise 对象
     */
    getOrderInfoOnListData(params) {
        return this.post(`/user/gjcOrderInfo/pageList`, params)
    }
    /**
    * 费用明细--线下费用查询（分页）
    */
    getOrderInfoListData(params) {
        return this.post(`/user/gjcOrderInfo/offlineFee/pageList`, params)
    }
    /**
    * 费用明细--线下费用总计
    */
    getOrderInfoCountData(params) {
        return this.post(`/user/gjcOrderInfo/offlineFee/count`, params)
    }
    /**
    * 费用明细详情
    */
    getOrderInfoDetailData(params) {
        return this.post(`/user/gjcOrderInfo/detail`, params, { isFormData: true })
    }
    /**
    * 挂号缴费详情
    */
    getAppointRecorDetailData(orderId) {
        return this.get(`/user/gjcAppointRecord/queryDetailByOrderId/${orderId}`)
    }
    /**
    * 订单详情
    */
    getOrderInfoOrderDetailData(orderId) {
        return this.get(`/user/gjcOrderInfo/orderDetail/${orderId}`)
    }
    /**
     * 住院每日清单
     */
    getDailyCheckListData(params) {
        return this.post(`/user/gjcZyOnLinePay/dailyCheckList`, params)
    }
    /**
    * 医院介绍
    * hospCode 机构代码
    */
    getHospitalIntroductionData(params) {
        return this.post(`/user/gjcHomePage/hospitalIntroduction`, params)
    }
    /**
    * 来院路线
    * hospCode 机构代码
    */
    getHospitalRouteData(params) {
        return this.post(`/user/gjcHomePage/hospitalRoute`, params)
    }

    /**
    * 药品价格公示分页查询
    */
    getDrugPageListData(params) {
        return this.post(`/user/gjcHomePage/drugPageList`, params)
    }

    /**
    * 医技价格公示分页查询
    */
    getInspectItemInfoPageListData(params) {
        return this.post(`/user/gjcHomePage/inspectItemInfoPageList`, params)
    }
    /**
    * 查询科室列表
    */
    getDepartDescData() {
        return this.get(`/user/gjcDepartDesc/data`)
    }
    /**
    * 查询科室介绍详情
    */
    getDeptDetailData(ksId) {
        return this.get(`/user/gjcDepartDesc/detail/${ksId}`)
    }
    /**
    * 查询科室下的医生信息
    */
    getDocDsecData(ksId) {
        return this.get(`/user/gjcDocDesc/data/${ksId}`)
    }
    /**
    * 查询医生详情
    */
    getDocDetailData(ysId) {
        return this.get(`/user/gjcDocDesc/detail/${ysId}`)
    }
    /**
    * 院内导航
    */
    getHospNavData(params) {
        return this.post(`/user/gjcHospNavigate/data`, params)
    }
    /**
    * 微信创建签名
    */
    getCreateJsapiSignatureData(params) {
        return this.post(`/user/createJsapiSignature`, params)
    }
    //--------------------------------------支付相关-----------------------------------
    /**
    * 医保支付授权页面 (本人)
    */
    getPayAuthSelfMsgData(appid, orderId) {
        return this.get(`/user/wxAuth/getPayAuthSelfMsg/${appid}/${orderId}`)
    }

    /**
    * 医保支付授权页面 (本人)
    */
    getPayAuthSelfInfoData(qrcode) {
        return this.get(`/user/wxAuth/getAuthSelectInfo/${qrcode}`)
    }
    /**
    * HIS缴费预算
    */
    getHisYbBudgetData(params) {
        return this.post(`/user/budget/hisYbBudget`, params)
    }

    /**
    * 创建医保支付
    */
    getCreateYbPayData(params) {
        return this.post(`/user/ybPay/createYbPay`, params)
    }
    /**
        * 支付完成后订单详情查询
        */
    ybOrderDetail(orderId) {
        return this.get(`/user/gjcOrderInfo/ybOrderDetail/${orderId}`)
    }
            /**
        * 刷新就诊人卡
        */
            refreshCard(id) {
                return this.get(`/user/gjcPatientInfo/refreshCard/${id}`)
            }
           /**
        * 签到定位开关
        */
           locationCheckSwitch() {
            return this.get(`/user/gjcSignInQueue/locationCheckSwitch`)
        }

        /**
         * 描述信息
         */
        gjcDepartDesc(descCode) {
            return this.get(`/user/gjcDepartDesc/data/${descCode}`)
        }
        /**
         * 跳转商城授权地址
         */

        mall(phone,loginStatus) {
            return this.get(`/user/openapi/mall/authorization/${phone}/${loginStatus}`)
        }
        /**
         * 跳转蓝生脑医商授权地址
         */

        naokechina(phone,loginStatus) {
            return this.get(`/user/openapi/naokechina/authorization/${phone}/${loginStatus}`)
        }
        //签到页面整改
        signInList(params) {
            return this.post(`user/gjcSignInQueue/signInList`, params)
        }
        
}

export const gjcModel = new GjcModel()
