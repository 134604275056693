<template>
  <div style="margin-top:16px">
    <table width="100%">
      <tbody>
        <tr>
          <th>{{ headObj.th1 }}</th>
          <th>{{ headObj.th2 }}</th>
          <th>{{ headObj.th3 }}</th>
          <th>{{ headObj.th4 }}</th>
        </tr>

        <tr v-for="item in data" :key="item.id">
          <td>{{ item.ypmc }}</td>
          <td>{{ item.mzxsdw }}</td>
          <td>{{ item.gg }}</td>
          <td>{{ item.lsj }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name:'PriceTable',
  props:{
    headObj:{
      type:Object,
      default:()=>{return {}}
    },
    data:{
      type:Array,
      default:()=>{[]}
    }

  }
}
</script>
<style scoped lang='less'>
// 样式写在index.less
</style>
