<!--
 * @Date: 2024-10-09 09:10:02
 * @LastEditTime: 2024-10-30 14:58:09
 * @Description: 
 * @FilePath: \szlsnk-user-mp\src\components\priceTable\gjcPriceCell.vue
-->
<template>
  <div style="margin-top:16px">
    <table width="100%">
      <tbody>
        <tr>
          <th v-for="(thItem, index) in headObj" :key="index">
            {{ thItem }}
          </th>
        </tr>
        <tr v-for="item in data" :key="item.id">
          <td v-for="(columnsItem,columnsIndex) in columnsData" :key="columnsIndex">
            {{ item[columnsItem] }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
    export default {
        name:'GjcPriceCell',
        props:{
            headObj:{
                type:Array,
                default:()=>{return []}
            },
            data:{
                type:Array,
                default:()=>{[]}
            },
            columnsData:{
                type:Array,
                default:()=>{[]}
            }
        },
        mounted() {
          console.log(this.headObj)
          console.log(this.data)
          console.log(this.columnsData)
        }
    }
</script>
<style scoped lang='less'>
    // 样式写在index.less
</style>
    