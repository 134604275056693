import { render, staticRenderFns } from "./gjcAppointmentItem.vue?vue&type=template&id=0ffaf72c&scoped=true"
import script from "./gjcAppointmentItem.vue?vue&type=script&lang=js"
export * from "./gjcAppointmentItem.vue?vue&type=script&lang=js"
import style0 from "./gjcAppointmentItem.vue?vue&type=style&index=0&id=0ffaf72c&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ffaf72c",
  null
  
)

export default component.exports