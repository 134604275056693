/*
 * @Author: your name
 * @Date: 2021-03-04 10:04:16
 * @LastEditTime: 2024-10-25 17:31:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\utils\index.js
 */
import { REG } from '@/utils/constant'

export function isDef(value) {
  return value !== undefined && value !== null
}

export function isDom(dom) {
  return typeof HTMLElement === 'object'
    ? dom instanceof HTMLElement
    : (dom && typeof dom === 'object' && dom.nodeType === 1 && typeof dom.nodeName === 'string')
}

export const calculateAge = (birthday) => {
  if (!birthday) {
    return
  }
  const currentDate = new Date()
  const birthDate = new Date(birthday)
  if (currentDate < birthDate) {
    return
  }
  const currentYear = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth() + 1
  const birthYear = birthDate.getFullYear()
  const birthMonth = birthDate.getMonth() + 1
  const yearDiff = currentYear - birthYear
  const monthDiff = currentMonth - birthMonth
  if (yearDiff === 0) {
    return monthDiff === 0 ? `${ currentDate.getDate() - birthDate.getDate() }天` : `${ monthDiff }个月`
  } else if (yearDiff > 0) {
    return monthDiff > 0 ? `${ yearDiff }岁` : `${ yearDiff - 1 }岁`
  }
}

export function formatDate(item) {
  const date = new Date(item)
  const YY = date.getFullYear() + '-'
  const MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
  const DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
  const hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  const mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
  const ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
  return YY + MM + DD + ' ' + hh + mm + ss
}

// 判断一个对象是否是空对象
export function isEmptyObject(obj) {
  return obj ? Object.keys(obj).length === 0 : true
}

export function generateAddress(data) {
  const obj = {}
  if (data) {
    obj.province_list = generateAddressObj(data['000000'])
    obj.city_list = generateChildren(data, obj.province_list)
    obj.county_list = generateChildren(data, obj.city_list)
  }

  return obj
}

function generateAddressObj(arr) {
  if (!arr) {
    return {}
  }

  return arr.reduce((result, item) => {
    result[item.clz] = item.clmc
    return result
  }, {})
}

function generateChildren(data, obj) {
  return Object.keys(obj).reduce((res, key) => {
    return { ...res, ...generateAddressObj(data[key]) }
  }, {})
}

export function combineUrl(baseUrl, url) {
  return url ? baseUrl.replace(/\/$/, '') + '/' + url.replace(/^\//, '') : baseUrl
}

export function getDate(type) {
  const date = new Date()

  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()

  if (type === 'start') {
    year = year - 60
  } else if (type === 'end') {
    year = year + 2
  }
  month = month > 9 ? month : '0' + month
  day = day > 9 ? day : '0' + day

  return `${ year }-${ month }-${ day }`
}

export function reduceDate(date, days) {
  var d = new Date(date)
  d.setDate(d.getDate() - days)
  var m = d.getMonth() + 1
  var day = d.getDate()
  m = m > 9 ? m : '0' + m
  day = day > 9 ? day : '0' + day
  return d.getFullYear() + '-' + m + '-' + day
}

export function addDate(date, days) {
  var d = new Date(date)
  d.setDate(d.getDate() + days)
  var m = d.getMonth() + 1
  var day = d.getDate()
  m = m > 9 ? m : '0' + m
  day = day > 9 ? day : '0' + day
  return d.getFullYear() + '-' + m + '-' + day
}

export function format(objDate) {
  let list = []
  for (let key in objDate) {
    let obj = {}
    obj.date = getday(key)
    obj.mouth = key.substring(5)
    obj.day = {}
    const data = objDate[key] || {}
    const keys = [1, 2]
    keys.forEach(item => {
      obj.day[item] = data[item] || {}
    })
    list.push(obj)
  }
  return list
}

function getday(d) {
  let weekDay = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
  let day = new Date(d)
  return weekDay[day.getDay()]
}

export function isPhoneNum(num) {
  if (num) {
    return REG.PHONE_NUM_REG.test(num)
  }

  return false
}

export function getIp() {
  return new Promise(resolve => {
    const conn = new RTCPeerConnection({
      iceServers: []
    })
    const noop = function () {
    }
    conn.onicecandidate = function (ice) {
      if (ice.candidate) {
        // 使用正则获取ip
        const ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/
        const ip = ipRegex.exec(ice.candidate.candidate)[1]
        conn.onicecandidate = noop
        resolve(ip)
      }
    }
    // 随便创建一个叫ip的通道(channel)
    conn.createDataChannel('ip')
    // 创建一个SDP协议请求
    conn.createOffer(conn.setLocalDescription.bind(conn), noop)
  })
}

export function encryptPhoneNum(num) {
  return num ? num.replace(/(\d{3})(\d{4})(\d{4})/, '$1****$3') : ''
}

export function encryptIdCordNum(num) {
  return num ? num.replace(/(\d{4})(\d{10})(\d{4})/, '$1********$3') : ''
}

export function convertDataURIToBinary(base64) {
  const raw = window.atob(base64)
  const rawLength = raw.length
  const array = new Uint8Array(new ArrayBuffer(rawLength))
  for (let i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i) & 0xff
  }
  return array
}

export function base64ToBlob(base64) {
  const u8arr = convertDataURIToBinary(base64)
  const blob = new Blob([u8arr], { type: 'application/pdf' })
  return URL.createObjectURL(blob)
}

// 防抖函数
export function debounce(func, wait, immediate) {
  let timeout
  return function(...args) {
    const context = this
    const later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}