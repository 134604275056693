/*
 * @Author: your name
 * @Date: 2021-03-02 13:23:16
 * @LastEditTime: 2024-11-14 18:02:38
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { appointmentRoutes } from './appointment'
import { patientRoutes } from './patient'
import { chatRoutes } from './chat'
import { userRoutes } from './user'
import { gjcRoutes } from './gjc'



Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/home',
    name: 'Home',
    // meta: {
    //   showBackBtn: false,
    //   pageBackground: 'grey-bg'
    // },
    component: () => import('../views/home/home.vue')
  },
  {
    path: '/',
    name: 'GjcMain',
    component: () => import('../views/gjc/gjcMain.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/login.vue')
  },
  // {
  //   path: '/register',
  //   name: 'Register',
  //   component: () => import('../views/register/register.vue')
  // },
  {
    path: '/introduce/privacy',
    name: 'privacy',
    meta: {
      title: '隐私保护政策'
    },
    component: () => import('../views/introduce/privacy/privacy.vue')
  },
  {
    path: '/agreeLetter',
    name: 'AgreeLetter',
    meta: {
      title: '互联网诊疗知情同意书'
    },
    component: () => import('../views/introduce/agreeLetter/agreeLetter.vue')
  },
  {
    path: '/introduce/service',
    name: 'service',
    meta: {
      title: '用户协议'
    },
    component: () => import('../views/introduce/service/service.vue')
  },
  {
    path: '/pricePublicity',
    name: 'PricePublicity',
    meta: {
      title: '价格公示'
    },
    component: () => import('../views/price/pricePublicity.vue')
  },

  {
    path: '/hospitalIntroduction',
    name: 'HospitalIntroduction',
    meta: {
      title: '医院介绍'
    },
    component: () => import('../views/static/hospitalIntroduction.vue')
  },
  {
    path: '/hospitalRoute',
    name: 'HospitalRoute',
    meta: {
      title: '来院路线'
    },
    component: () => import('../views/static/hospitalRoute.vue')
  },
  {
    path: '/invoice/:orderNumber',
    name: 'Invoice',
    meta: {
      title: '开票'
    },
    component: () => import('../views/invoice/Invoice')
  },
  ...appointmentRoutes,
  ...patientRoutes,
  ...chatRoutes,
  ...userRoutes,
  ...gjcRoutes
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
