/*
 * @Author: your name
 * @Date: 2021-03-05 14:45:17
 * @LastEditTime: 2021-03-05 14:45:35
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\store\modules\video-chat.js
 */
import * as types from '../../common/mutation-types'

// 在项目中使用chat模块时,store文件夹下的文件名需使用video-chat,公共包中代码默认模块名就是video-chat

  export const state= {

      // 多人会议创建成功后的信息
      conferenceConfig: {},
      // 网络差的标识
      isWeakNetwork: false,
      noMediaDevice: false,
      memberList: []

  }
  export const getters= {
    conferenceConfig(state) {
      return state.conferenceConfig
    },
    isWeakNetwork(state) {
      return state.isWeakNetwork
    },
    noMediaDevice(state) {
      return state.noMediaDevice
    },
    memberList(state) {
      return state.memberList
    }
  }
  export const mutations={
    // 保存成功创建会议后的信息
    [types.SET_CONFERENCE_CONFIG](state, config) {
      state.conferenceConfig = config
    },
    // 网络差或断网
    [types.SET_WEAK_NETWORK](state, flag) {
      state.isWeakNetwork = flag
    },
    // 跟新设备是否有摄像头等设备
    [types.SET_MEDIA_ERROR](state, flag) {
      state.noMediaDevice = flag
    },
    // 更新会议的人员
    [types.SET_MEMBER_LIST](state, { name, exit }) {
      state.memberList = exit ? state.memberList.filter(nickName => nickName !== name) : [...state.memberList, name]
    }
  }
  export default {
    namespaced: true,
    state,
    getters,
    mutations
}

