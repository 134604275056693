<!--
 * @Author: your name
 * @Date: 2021-03-02 18:23:05
 * @LastEditTime: 2021-03-09 18:43:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\components\header\Header.vue
-->
<template>
  <div :class="['header', headerCompConfig.bgClass]">
    <div
      v-if="headerCompConfig.showBackBtn"
      class="left-icon"
      @click="clickBackBtn"
    >
      <van-icon name="arrow-left" />
    </div>
    <!-- <div v-if="showLogo" class="logo-img">
      <img src="./images/logo.png" class="img" alt>
    </div>-->
    <div class="text">
      {{ headerCompConfig.title }}
    </div>
    <div
      v-if="headerCompConfig.showRightBtn"
      :class="['right-btn', {'active': headerCompConfig.rightBtnActive}]"
      @click="headerCompConfig.clickRightBtn"
    >
      {{ headerCompConfig.rightBtnText }}
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({headerCompConfig:'header/headerCompConfig'})
  },
  methods:{
    clickBackBtn(){
      if (this.headerCompConfig.customBackEvent) {
        this.$router.push('/')
      } else {
        this.$router.back()
      }
    }
    // clickRightBtn() {
    //   console.log('Header右上按钮响应')
    // }
  }
}
</script>
<style scoped lang='less'>
@import "../../assets/styles/variable.less";
@import "../../assets/styles/mixins.less";
.header {
  width: 100%;
  height: 90px;
  position: fixed;
  top: 0;
  left: 0;
  z-index:10000;

  &.white-bg {
    background-color: @white-color;
    color: @dark-font-color;
    border-bottom: 1px solid @border-color;
  }
  &.theme-bg {
    background-color: @theme-color;
    color: @white-color;
  }

  .left-icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 72px;
    height: 100%;
    padding-left:10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .van-icon-arrow-left {
      font-size: 48px;
    }
  }

  .right-btn {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    line-height: 90px;
    padding-right: 32px;
    font-size: @font-size-32;
    color: @middle-font-color;
    font-weight: 500;

    &.active {
      color: @theme-color;
    }
  }
  .logo-img {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .img {
      width: 211px;
      height: 52px;
    }
  }
  .text {
    //  padding: 0 96px;
    line-height: 90px;
    text-align: center;
    font-size: @font-size-36;
    font-weight: bold;
    .ellipsis();
  }
}
</style>
