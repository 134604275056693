<template>
  <div>
    <div v-for="drug in data" :key="drug.id" class="card-item">
      <div class="cell">
        <div class="cell_title">
          药物:
        </div>
        <div class="cell_value middle-color">
          {{ drug.xmbzmc }}
        </div>
      </div>
      <div class="cell">
        <div class="cell_title">
          规格:
        </div>
        <div class="cell_value middle-color">
          {{ drug.gg }}
        </div>
      </div>
      <div class="cell">
        <div class="cell_title">
          数量:
        </div>
        <div class="cell_value middle-color">
          {{ drug.fysl }}{{ drug.ftdw }}
        </div>
      </div>
      <div class="cell">
        <div class="cell_title">
          金额:
        </div>
        <div class="cell_value middle-color red-color">
          ¥ {{ drug.dj }}
        </div>
      </div>
      <div class="middle-color mt24 font-size">
        用法用量：{{ drug.yfmc }}，每次{{ drug.dcjl }}{{ drug.dcjldw }}，{{ drug.pcmc }}，用药{{ drug.yyts }}天
      </div>
      <!--      <div class="row">-->
      <!--        <div v-if="!showButton" class="drug-img">-->
      <!--          <img src="../../assets/img/thumbnail.png" alt="">-->
      <!--        </div>-->
      <!--        <div class="right">-->
      <!--          <div class="drugName">-->
      <!--            {{ drug.xmbzmc }} <span class="count">x{{ drug.fysl }}</span>-->
      <!--          </div>-->
      <!--          <div class="middle-color size24 margin14">-->
      <!--            {{ drug.gg }}-->
      <!--          </div>-->
      <!--          <div class="red-color">-->
      <!--            ¥ {{ drug.dj }}-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="middle-color mt24">-->
      <!--        用法用量：{{ drug.yfmc }}，每次{{ drug.dcjl }}{{ drug.dcjldw }}，{{ drug.pcmc }}，用药{{ drug.yyts }}天-->
      <!--      </div>-->
    </div>
    <!--    <van-button v-if="showButton" round size="small" type="info" class="btn" @click.stop="sure">-->
    <!--      确认-->
    <!--    </van-button>-->
  </div>
</template>
<script>
export default {
  name:'DrugItem',
  props:{
    data:{
      type:Array,
      default:()=>{return []}
    },
    showButton:{
      type:Boolean,
      default:false
    }
  }
}
</script>
<style scoped lang='less'>
@import "../../assets/styles/variable.less";
  .card-item{
    width: 100%;
    background: #FFFFFF;
    margin-top: 50px;
    .cell {
      position: relative;
      display: flex;
      box-sizing: border-box;
      width: 100%;
      padding: 16px 0;
      font-weight: 400;
      overflow: hidden;
      color: @dark-font-color;
      font-size: 28px;
      line-height: 40px;
    }

    .cell_title, .cell_value {
      flex: 1 1 auto;
    }

    .cell_value {
      overflow: hidden;
      text-align: right;
    }
    &:first-child{
      margin-top: 32px;
    }
    .row{
      display: flex;
      .drug-img{
        width:139px;
        height: 139px;
        flex:0 0 139px;
        margin-right: 34px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .right{
        line-height: 40px;
        flex: 1;
      }
      .drugName{
        width: 100%;
        font-size: 24px;
        font-weight: bold;
        color: #666;
        margin-right: 10px;
        .count{
          float: right;
        }
      }

      .margin14{
        margin: 14px 0;
      }
      .font-size{
        font-size:28px;
      }

    }
  }

  /deep/ .van-button--round{
    width:100% !important;
    margin-top:20px;
  }

</style>
