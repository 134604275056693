<template>
  <div class="order-list">
    <div v-for="i in [1,2]" :key="i" class="card-box">
      <div class="row border-b">
        <div class="title">
          订单ID：1234533244
        </div>
        <div class="right">
          待支付
        </div>
      </div>
      <div class="padding">
        <drugItem />
      </div>

      <div class="row border-top">
        <div class="left">
          购买时间：2020-01-11  10:26:30
        </div>
        <div class="right">
          <div class="status" @click="routeTo('/orderDetail')">
            查看详情
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name:"OrderItem",
  props:{
    data:{
      type:Array,
      default: () => ([])
    }
  }
}
</script>
<style scoped lang="less">
@import "../../assets/styles/variable.less";
.order-list{
  padding: 24px 32px;
}
.card-box{
  width: 100%;
  background: #FFF;
  border-radius: 16px;
  margin-bottom: 24px;
  box-sizing: border-box;
  z-index: 2;
  .padding{
    padding: 0 32px 20px;
  }
  .row{
    display: flex;
    padding: 20px 32px;

    .left{
      font-size: 24px;
      color: #666666;
      line-height: 48px;
    }
    .title{
      font-size: 28px;
      font-weight: bold;
      color: @dark-font-color;
    }
    .right{
      flex: 1;
      font-size: 24px;
      color: #666666;
      text-align: right;
    }

    .status{
      width: 138px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      border-radius: 24px;
      border: 1px solid #1C9CF6;
      color: #1C9CF6;
      float: right;
    }
  }
  .border-b{
    border-bottom: 1px solid #dddddd;
  }
  .border-top{
    border-top: 1px solid #dddddd;
  }
}
</style>
