import * as types from '../mutation-types'
import { formatDate } from '@/utils'
import { MessageStatus } from '@/utils/constant'

const DIFF_TIME = 2 * 60 * 1000

function sortMsgList(msgList) {
  return msgList.sort((a, b) => a.time - b.time)
}

// 在项目中使用chat模块时,store文件夹下的文件名需使用chat, 公共包中代码默认模块名就是chat
export default {
  namespaced: true,
  state() {
    return {
      // 聊天集合
      messageDict: {},
      // 当前聊天用户id --形式：自己的用户名_对方的用户名_类型（极速和图文）
      chatId: '',
      // 环信是否登录
      isImLogin: false,
      // 标记咨询是否结束
      finishedMap: {}
    }
  },
  getters: {
    // 当前聊天的列表
    currentMsgList(state) {
      return state.messageDict[state.chatId] || []
    },
    // 当前咨询是否结束
    isFinishedCurrentConsult(state) {
      return state.finishedMap[state.chatId] || false
    },
    // 环信是否登录
    isImLogin(state) {
      return state.isImLogin
    },
    messageDict(state) {
      return state.messageDict
    },
    chatId(state) {
      return state.chatId || ''
    }
  },
  mutations: {
    // 更新消息列表
    [types.UPDATE_MESSAGE_LIST](state, { message, chatModel }) {
      console.log('update msg list', message)
      const { chatId, msg, bySelf, type = '', mid, time, status = MessageStatus.SENT } = message

      // 从缓存中读取消息列表，多个tab打开同一个聊天窗口，store中的数据不一定是最全的
      const messageDict = chatModel.getMessageDict()
      const msgList = messageDict[chatId] || []
      const index = msgList.findIndex(item => item.mid === mid)
      // 环信会同时保持多个tab活跃，保证收到消息时只存入一次，避免重复多次存入
      if (index === -1) {
        const msgObj = {
          ...message,
          msg,
          bySelf,
          type,
          mid,
          status,
          time
        }

        // 控制时间显示,当前消息与前一条消息在两分钟间隔内，不显示时间
        const lastIndex = msgList.length - 1
        const showTime = lastIndex >= 0 && msgObj.time - msgList[lastIndex].time < DIFF_TIME
        msgObj.showTime = showTime ? '' : formatDate(msgObj.time, 'yyyy-MM-dd hh:mm')

        msgList.push(msgObj)
        // 按照时间排序
        messageDict[chatId] = sortMsgList(msgList)
        state.messageDict = messageDict
        // 聊天记录存入缓存
        chatModel.setMessageDict(messageDict)
      }
    },
    // 更新当前聊天对象
    [types.SET_CHAT_ID](state, chatId) {
      state.chatId = chatId
    },
    // 更新当前咨询的状态
    [types.SET_FINISH_STATUS](state, { chatId, finished }) {
      state.finishedMap[chatId] = finished
      // 触发getters更新
      state.finishedMap = { ...state.finishedMap }
    },
    // 更新环信登录状态
    [types.SET_LOGIN_STATUS](state, isLogin) {
      state.isImLogin = isLogin
    },
    // 更新消息状态，已读，未读等
    [types.UPDATE_MESSAGE_STATUS](state, { message, chatModel }) {
      const { id, mid, status } = message
      const messageDict = chatModel.getMessageDict()

      const msgList = messageDict[chatModel.chatId] || []
      msgList.forEach(msg => {
        if (msg.mid === id || msg.mid === mid) {
          msg.status = status
          if (message.msg) {
            msg.msg = message.msg
          }
        }
      })

      state.messageDict[chatModel.chatId] = msgList
      messageDict[chatModel.chatId] = msgList
      chatModel.setMessageDict(messageDict)
    },
    // 将发送消息时生成的mid替换成返回的mid，用于匹配已读未读回调的id
    [types.UPDATE_MESSAGE_MID](state, { message, chatModel }) {
      const { id, mid } = message
      const messageDict = chatModel.getMessageDict()
      const msgList = messageDict[chatModel.chatId] || []

      msgList.forEach(msg => {
        if (msg.mid === id) {
          msg.mid = mid
        }
      })

      state.messageDict[chatModel.chatId] = msgList
      messageDict[chatModel.chatId] = msgList
      chatModel.setMessageDict(messageDict)
    },
    // 发送已读回执, 发送给对方，不需要更新当前state的数据
    [types.UPDATE_READ_STATUS](state, chatModel) {
      const messageDict = chatModel.getMessageDict()
      const currentMsgList = messageDict[state.chatId] || []

      currentMsgList.forEach(msg => {
        // 渲染列表时向对方发送消息已读回执
        if (!msg.bySelf && msg.status !== MessageStatus.READ) {
          chatModel.sendReadMsg(msg)
          msg.status = MessageStatus.READ
        }
      })

      chatModel.setMessageDict(messageDict)
    },
    // 更新消息集合
    [types.SET_MESSAGE_DICT](state, { messageDict, chatModel }) {
      state.messageDict = messageDict
      chatModel.setMessageDict(messageDict)
    },
    // 更新当前聊天的列表
    [types.SET_CURRENT_MESSAGE_LIST](state, { chatModel, msgList }) {
      const messageDict = chatModel.getMessageDict()
      messageDict[chatModel.chatId] = msgList
      state.messageDict = messageDict
      chatModel.setMessageDict(messageDict)
    }
  }
}
