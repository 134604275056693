<template>
  <div>
    <div v-for="(item,index) in data" :key="index" class="card-item">
      <div class="docbox">
        <div class="photo">
          <default-image :url="item.txljdz" is-avatar round />
        </div>
        <div class="mid">
          <div class="dark-color">
            <span class="name">{{ item.ysxm }}</span> <span class="size28">{{ item.zc==null?'无':item.zc }}</span>
          </div>
          <div class="size28 middle-color keshi">
            {{ item.ksmc }}
          </div>
          <div class="status" @click="call(item.ysmobile)">
            联系医生
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name:'DoctorList',
    props:{
      data: {
        type: Array,
        default: () => ([])
      }
    },
    methods:{
      call(mobile){
        this.$dialog.alert({
            message: '医生手机号：'+mobile
        })
      }
    }
  }
</script>
<style scoped  lang='less'>
@import "../../assets/styles/variable.less";
.card-item{
  width: 100%;
  background: #FFFFFF;
  border-radius: 12px;
  margin-bottom: 32px;

  .docbox{
    display: flex;
    padding: 32px;
    justify-content: space-between;
    box-sizing: border-box;
    .photo{
      width:102px;
      flex:0 0 102px;
      height: 102px;
      margin-right: 32px;
    }
    .mid{
      flex: 1;
      line-height: 40px;
    }
    .name{
      font-size: 32px;
      color: @dark-font-color;
      font-weight: bold;
      margin-right: 24px;
    }
  }
  .cell{
    display: flex;
    width: 100%;
    font-size: 24px;
    line-height: 60px;
  }

  .cell_title, .cell_value{
    flex: 1;
  }
  .cell_value{
    text-align: right;
  }

}
.keshi{
  width: 200px;
  display: inline-block;
  margin-top: 10px;

}

.status{
  width: 138px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  border-radius: 24px;
  border: 1px solid #1C9CF6;
  color: #1C9CF6;
  float: right;
}


</style>
