<!--
 * @Author: your name
 * @Date: 2021-03-04 10:04:16
 * @LastEditTime: 2024-11-12 16:04:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\components\pageRefreshLoad\pageRefreshLoad.vue
-->
<template>
  <van-pull-refresh v-model="pageRefreshing" :class="pullClassName" :disabled="refreshEnable" @refresh="onRefresh">
    <van-list
      v-model="listLoading"
      :class="className"
      :finished="finished"
      loading-text="正在加载中..."
      :finished-text="total > 0 && finished ? '没有更多了' : ''"
      offset="30"
      :immediate-check="false"
      @load="onLoad"
    >
      <slot v-if="total" />
      <slot v-else name="noData">
        <no-found />
      </slot>
    </van-list>
  </van-pull-refresh>
</template>

<script>
  import NoFound from '../nofound/NoFound'

  export default {
    name: 'PageRefreshLoad',
    components: { NoFound },
    props: {
      dataSource: {
        type: Array,
        default: () => ([])
      },
      total: {
        type: Number,
        default: 0
      },
      loading: {
        type: Boolean,
        default: false
      },
      refreshing: {
        type: Boolean,
        default: false
      },
      className: {
        type: String,
        default: 'load-class'
      },
      pullClassName: {
        type: String,
        default: 'pull-class'
      },
      // 是否开启下拉刷新 默认关闭
      refreshEnable: {
        type: Boolean,
        default: true
      },
      // 超脱一定数量才展示没有更多
      count: {
        type: Number,
        default: 10
      }
    },
    data() {
      return {
        listLoading: false,
        pageRefreshing: false,
        isLoadingMore: false,
        isRefresh:false
      }
    },
    computed: {
      finished() {
        return this.dataSource.length >= this.total
      }
    },
    watch: {
      refreshing: {
        handler(val) {
          this.pageRefreshing = val
          this.isRefresh = val
        },
        immediate: true
      },
      loading: {
        handler(val) {
          this.listLoading = val
          this.isLoadingMore = val
        },
        immediate: true
      }
    },
    methods: {
      onLoad() {
        // 子组件和父组件数据交互有延迟，因此新增一个参数来控制加载状态
        // 未加载完，且不是正在加载中，才触发加载更多
        if (!this.isLoadingMore) {
          this.isLoadingMore = true
          this.$emit('load-more')
        }
      },
      onRefresh() {
        // 子组件和父组件数据交互有延迟，因此新增一个参数来控制加载状态
        // 未加载完，且不是正在加载中，才触发加载更多
        if (!this.isRefresh) {
          this.isRefresh = true
          this.$emit('refreshing')
        }
      }
    }
  }
</script>

<style scoped lang="less">
.load-class{
  background: #F5F5F5;
}
.van-list{
  padding: 16px;
}

// .pull-class {
//   padding-top: 110px;
// }

.message {
  background-color: transparent;
}
</style>