/*
 * @Author: your name
 * @Date: 2021-03-04 18:55:57
 * @LastEditTime: 2021-03-08 18:50:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\store\modules\patient.js
 */
import * as types from '../mutation-types'
import {isEmptyObject,generateAddress} from '../../utils/index'
import {constantModel}from '@/api/constant'
import {patientModel} from '@/api/patient'
export const state={
  patientRelationship: {},
  areaList: {},
  relationList:[],
  list:[{jzklxmc:'医保卡',jzklx:"1",type:'first'},{jzklxmc:'院内卡',jzklx:"2",type:'second'}],
  chooseList:{}
}
export const getters={
  patientRelationship(state) {
    return state.patientRelationship
  },
  getChoosePatient(state){
    return state.chooseList
  },
  areaList(state) {
    // console.dir(state.areaList,"开发")
    return state.areaList
  },
  relationList(state){
    // console.dir(state.relationList,"开发")
    return state.relationList
  },
  list(state){
    return state.list
  }
}
export const mutations={
  [types.SET_PATIENT_RELATIONSHIP](state, value) {
    state.patientRelationship = value
  },
  [types.SET_CHOOSE_LIST](state,value){
    state.chooseList=value
  },
  [types.SET_AREA_LIST](state, areaList) {
    state.areaList = areaList
  },
  [types.SET_RELATION_LIST](state,relationList){

    state.relationList=relationList
    // console.dir(state.relationList,'relationList')
  },
  [types.ADD_LIST](state,cardList){
    state.list.push(cardList)
  }
}
export const actions={
  async getPatientRelation({ commit, getters }) {
    if (getters.isLogin) {
      // const result = await this.$api.userModel.getPatientRelationship()
      commit(types.SET_PATIENT_RELATIONSHIP,{})
    }
  },
  async getAreaList({ commit, getters }) {
    console.log(isEmptyObject(getters.areaList),"查看")
    if (isEmptyObject(getters.areaList)) {
     const { data } = await constantModel.getAddress()
     const areaList = generateAddress(data)
      commit(types.SET_AREA_LIST, areaList)
    }
  },
  async getRelation({ commit }){
    const fzdm='jtgx'
    const list=[]
      const {data}=await patientModel.getRelation({fzdm})
      data.map((k)=>{
        console.log(k,"kkk")
        let obj = {}
        obj.key = k.clz
        obj.value = k.clmc
        list.push(obj)
      })
        console.log(list,"结果")
      commit(types.SET_RELATION_LIST, list)
  }
  // getAreaList({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     constantModel.getAddress().then(res => {
  //       console.dir(res,"卧槽")
  //       if (res.data) {
  //         commit(types.SET_AREA_LIST, '')
  //         resolve(res)
  //       }

  //       reject()
  //     })
  //   })
  // }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
