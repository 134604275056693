/*
 * @Author: your name
 * @Date: 2021-03-02 13:23:16
 * @LastEditTime: 2024-10-09 17:08:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\vant.js
 */
import Vue from 'vue'
import {
  Tabbar,
  TabbarItem,
  Toast,
  Form,
  Field,
  RadioGroup,
  Radio,
  Uploader,
  Button,
  List,
  Icon,
  Tab,
  Tabs,
  TreeSelect,
  Popup,
  Grid,
  Picker,
  GridItem,
  Row,
  Col,
  Cell,
  Dialog,
  CellGroup,
  Checkbox,
  DatetimePicker,
  Switch,
  AddressList,
  Area,
  Tag,
  Rate,
  Search,
  PullRefresh,
  SwipeCell,
  Sticky,
  Swipe,
  SwipeItem,
  Empty,
  Popover,
  Badge,
  Calendar,
  NoticeBar,
  Divider
} from 'vant'
const vantComponents = [
  Tabbar,
  TabbarItem,
  Toast,
  Form,
  Field,
  Radio,
  RadioGroup,
  Uploader,
  Button,
  List,
  Icon,
  Tab,
  Tabs,
  TreeSelect,
  Picker,
  Popup,
  Grid,
  GridItem,
  Row,
  Col,
  Cell,
  CellGroup,
  Checkbox,
  Dialog,
  Picker,
  Popup,
  DatetimePicker,
  Switch,
  AddressList,
  Area,
  Tag,
  Rate,
  Search,
  PullRefresh,
  SwipeCell,
  Sticky,
  Swipe,
  SwipeItem,
  Empty,
  Popover,
  Badge,
  Calendar,
  NoticeBar,
  Divider
]

// 全局注册vant组件
vantComponents.forEach((component) => {
  Vue.use(component)
})
