<!--
 * @Author: your name
 * @Date: 2021-03-04 10:04:16
 * @LastEditTime: 2021-03-10 11:22:32
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\components\load-more\LoadMore.vue
-->
<template>
  <van-pull-refresh :class="pullClassName" :value="refreshing" :disabled="!refreshEnable" @refresh="onRefresh">
    <template v-if="total">
      <van-list
        :class="className"
        :value="loading"
        :finished="finished"
        :finished-text="total > count ? '没有更多了' : ''"
        :immediate-check="false"
        offset="30"
        @load="onLoad"
      >
        <slot />
      </van-list>
    </template>
    <slot v-else name="noData">
      <no-found />
    </slot>
  </van-pull-refresh>
</template>

<script>
  import NoFound from '../nofound/NoFound'

  export default {
    name: 'LoadMore',
    components: { NoFound },
    props: {
      dataSource: {
        type: Array,
        default: () => ([])
      },
      total: {
        type: Number,
        default: 0
      },
      loading: {
        type: Boolean,
        default: false
      },
      refreshing: {
        type: Boolean,
        default: false
      },
      className: {
        type: String,
        default: 'load-class'
      },
      pullClassName: {
        type: String,
        default: 'pull-class'
      },
      refreshEnable: {
        type: Boolean,
        default: false
      },
      // 超脱一定数量才展示没有更多
      count: {
        type: Number,
        default: 10
      }
    },
    computed: {
      finished() {
        return this.dataSource.length >= this.total
      }
    },
    methods: {
      onLoad() {
        if (!this.loading) {
          this.$emit('load-more')
        }
      },
      onRefresh() {
        if (!this.refreshing) {
          this.$emit('refreshing')
        }
      }
    }
  }
</script>

<style scoped lang="less">
.load-class{
  background: #F5F5F5;
}
.van-list{
  padding: 16px;
}

// .pull-class {
//   padding-top: 110px;
// }

.message {
  background-color: transparent;
}
</style>
