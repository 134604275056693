<template>
  <div :class="['img-wrap', {'round': round}]" :style="style" @click="onClick">
    <img
      :key="imgKey"
      :src="url || defaultUrl"
      :class="[{'avatar':isAvatar},{'round': round}, {'img': scale}]"
      :width="width"
      :height="height"
      alt=""
    >
  </div>
</template>

<script>
  export default {
    name: 'DefaultImage',
    props: {
      // 图片地址
      url: {
        type: String,
        default: ''
      },
      // 图片宽度
      width: {
        type: String,
        default: '46'
      },
      // 图片高度
      height: {
        type: String,
        default: '46'
      },
      // 是否圆形显示
      round: {
        type: Boolean,
        default: false
      },
      // 是否是头像
      isAvatar: {
        type: Boolean,
        default: false
      },
      // 悬浮图片是否放大
      scale: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      defaultUrl() {
        return this.isAvatar ? require('../../assets/img/avatar.png') : require('../../assets/img/thumbnail.png')
      },
      imgKey() {
        return (this.url || this.defaultUrl) + Date.now().toString()
      },
      style() {
        return {
          width: this.isNumber(this.width) ? `${this.width}px` : this.width,
          height: this.isNumber(this.height) ? `${this.height}px` : this.height
        }
      }
    },
    methods: {
      onClick() {
        this.$emit('click-img')
      },
      isNumber(value) {
        const reg = /^\d*$/g
        return reg.test(value)
      }
    }
  }
</script>

<style scoped lang="less">
.round {
  border-radius: 50%;
}

img {
  transition: all 0.3s;
  object-fit: cover;
  object-position: 0 0;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  &:hover {
    transform: scale(1.2);
  }
}

</style>
