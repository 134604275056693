import { render, staticRenderFns } from "./orderItem.vue?vue&type=template&id=a3aa9498&scoped=true"
import script from "./orderItem.vue?vue&type=script&lang=js"
export * from "./orderItem.vue?vue&type=script&lang=js"
import style0 from "./orderItem.vue?vue&type=style&index=0&id=a3aa9498&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3aa9498",
  null
  
)

export default component.exports