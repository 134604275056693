<template>
  <div class="tab-container">
    <van-tabs color="#3A8AE5" @click="onClick">
      <van-tab v-for="(item,index) in tabs" :key="index" :title="item.label" :name="item.name">
        <slot :tab="item" :index="index" />
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
export default {
  name:'Tabs',
  props:{
    tabs: {
      type: Array,
      default: () => ([])
    },
    tab: {
      type: Object,
      default: null
    }
  },
  methods: {
    onClick(name){
      this.$emit('activeName',name)
    }
  }
}
</script>
