<!--
 * @Author: your name
 * @Date: 2021-03-06 15:08:22
 * @LastEditTime: 2021-03-08 17:06:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\components\category\addressItem.vue
-->
<template>
  <div>
    <van-field
      :value="address"
      is-link
      label="所在地区 "
      readonly
      name="address"
      placeholder="请选择类型"
      :rules="[{ required: true, message: '请选择地址'}]"
      @click="addressPicker = true"
    />
    <van-popup v-model="addressPicker" position="bottom">
      <van-area :area-list="areaList" @confirm="onConfirm" @cancel="addressPicker=false" />
    </van-popup>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
export default {
  name:"AddressItem",
  props:{
    dpmc:{
      type:String,
      default: ''
    },
    dcmc:{
      type:String,
      default: ''
    },
    ddmc:{
      type:String,
      default: ''
    },
    address: {
      type:String,
      default: ''
    }
  },
  data(){
    return{
      addressPicker:false,
      area:[]
    }
  },
    computed:{
    ...mapGetters({
      areaList:'patient/areaList'
    })
  },
  async mounted(){
    await this.$store.dispatch('patient/getAreaList')
  },

  methods:{
    onConfirm(value){
      this.$emit("addressChange",value)
      this.addressPicker=false
    }
  }
}
</script>
<style scoped lang='less'>
</style>
