<!--
 * @Author: your name
 * @Date: 2021-03-02 13:58:36
 * @LastEditTime: 2024-11-14 18:27:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\App.vue
-->
<template>
  <div>
    <Header v-show="!showHeader" />
    <keep-alive :include="includes">
      <router-view :class="['page-wrap',page_bg]" />
    </keep-alive>
    <!-- <TabBar v-show="showTab" /> -->
  </div>
</template>
<script>
// import TabBar from '../src/components/tabbar/Tabbar'
import Header from '../src/components/header/Header'
// import {WX_CONST} from './utils/constant'
// import {appid} from './utils/constant'
export default {
  components:{
    // TabBar,
    Header
  },
  data(){
    return{
      page_bg:'',
      includes:['Home','GjcMain']
    }
  },
  computed: {
    showTab() {
      return [ '/user', '/consult'].includes(this.$route.path)
    },
    showHeader(){
      return ['/','/login','/register'].includes(this.$route.path)
    }
  },
  watch:{
    $route(to){
      this.page_bg = to.meta.pageBackground
    }
  },
  mounted(){
    // console.log(this.showHeader,'showHeader')
  }


}
</script>
<style scoped lang="less">
.page-wrap {
  position: fixed;
  top: 90px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  overflow: auto;
}
.grey-bg{
  background: #f5f5f5;
}
  .van-tabbar {
    z-index: 1000;
  }
</style>
