<template>
  <div>
    <div v-for="item in data" :key="item.id" class="card-item">
      <div class="docbox">
        <div class="photo">
          <default-image is-avatar round />
        </div>
        <div class="mid">
          <div class="dark-color">
            <span class="name">{{ item.ysxm }}</span>&nbsp; <span class="desc">{{ item.zc }}</span>
          </div>
          <div class="desc mt10">
            {{ item.ksmc }}
          </div>
        </div>

        <div class="status blue" @click="routeTo(item)">
          {{ type == "1" ? item.yydztmc : item.ddztmc }}
        </div>
      </div>
      <div class="border" />
      <div class="cell">
        <div class="cell_title">
          就诊人：
        </div>
        <div class="cell_value">
          {{ item.jzrxm }}
        </div>
      </div>
      <div class="cell">
        <div class="cell_title">
          预约日期：
        </div>
        <div class="cell_value">
          {{ item.jzrq ? item.jzrq.substring(0, 10) : "" }} {{ item.sxwbzmc }}
        </div>
      </div>
      <div class="cell">
        <div class="cell_title">
          门诊类型：
        </div>
        <div class="cell_value">
          {{ item.hylxmc }}
        </div>
      </div>
      <div class="cell">
        <div class="cell_title">
          挂号费用：
        </div>
        <div class="cell_value">
          ¥ {{ item.ghf }}
        </div>
      </div>
      <div class="cell">
        <div class="cell_title">
          病情主诉：
        </div>
        <div class="cell_value">
          {{ item.bqzs == "" || item.bqzs == null ? "暂无" : item.bqzs }}
        </div>
      </div>
      <div v-if="type == '2' && item.ddzt === '1'">
        <div class="border" />
        <div class="btnbox">
          <div class="btn status blue" @click="cancelsChoose(item)">
            退费
          </div>
        </div>
      </div>
    </div>
    <van-dialog
      v-model="show"
      :title="'请选择退费原因'"
      :loading="loading"
      show-confirm-button
      show-cancel-button
      theme="round-button"
      confirm-button-text="确定"
      cancel-button-text="取消"
      confirm-button-color="#3A8AE5"
      cancel-button-color="#DDDDDD"
      :before-close="clickConfirm"
    >
      <div class="reason-list">
        <div
          v-for="(v, index) in cancelContent"
          :id="v.id"
          :key="v.id"
          class="reason-item"
          :data-name="v.name"
          :data-current="index"
          :class="tabIndex == index ? 'active' : ''"
          @click="cancelChoose(v, index)"
        >
          {{ v.name }}
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import { addDate } from "../../utils/index"
import { userModel } from "@/api/user"
export default {
  name: "AppointmentItem",
  props: {
    data: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: ""
    }
    //type=2 挂号，1预约
  },
  data() {
    return {
      registerId: null,
      tabIndex: null,
      show: false,
      reason: "",
      loading: false,
      cancelContent: [
        {
          name: "临时有事",
          id: "nsys"
        },
        {
          name: "重复预约",
          id: "cfyy"
        },
        {
          name: "不想去了",
          id: "bxql"
        },
        {
          name: "约错时间",
          id: "ycsj"
        },
        {
          name: "约错科室",
          id: "ycks"
        },
        {
          name: "约错医生",
          id: "ycys"
        }
      ]
    }
  },
  methods: {
    routeTo(item) {
      if (item.jzrq.substr(0, 10) > addDate(new Date(), 0)) {
        this.$toast("请在当日挂号！")
        return false
      }
      if (this.type == "2") {
        if (item.thbz == "0") {
          //退号标志：0未支付 1 已支付 2 退号 3支付中 4支付失败
          this.$router.push(
            `/appointment/detail?id=${item.id}&type=${this.type}`
          )
        } else {
          this.$router.push(
            `/appointment/success?id=${item.registerId}&thbz=${item.thbz}`
          )
        }
      } else {
        // if(item.yydzt == '0') //预约单状态:0 预约成功
        this.$router.push(
          `/appointment/detail?id=${item.id}&type=${this.type}&yydzt=${item.yydzt}`
        )
      }
    },
    cancelsChoose(item) {
      this.show = true
      this.registerId = item.registerId
    },
    cancelChoose(item, index) {
      this.reason = item.name
      this.tabIndex = index
    },
    clickConfirm(action, done) {
      if (action === "confirm") {
        if (this.reason == "") {
          this.$toast("请选择退费原因!")
          return done(false)
        }
        this.loading = true
        userModel
          .ghRefundApply({ reason: this.reason, registerOrderId: this.registerId })
          .then(() => {
            this.$toast("退费申请已提交!")
            this.$emit("refresh")
          })
          .finally(() => {
            this.loading = false
            this.reason = ""
            this.tabIndex = null
            done()
          })
      } else {
        this.reason = ""
        this.tabIndex = null
        done()
      }
    }
  }
}
</script>
<style scoped  lang='less'>
@import "../../assets/styles/variable.less";
.card-item {
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  margin-bottom: 32px;
  padding-bottom: 32px;

  .docbox {
    display: flex;
    padding: 32px;
    justify-content: space-between;
    box-sizing: border-box;
    .photo {
      width: 92px;
      flex: 0 0 92px;
      height: 92px;
      margin-right: 16px;
    }
    .mid {
      flex: 1;
      line-height: 40px;
    }
    .name {
      font-size: 32px;
      color: @dark-font-color;
      font-weight: bold;
      margin-right: 10px;
    }
    .desc {
      font-size: 28px;
      color: #3d3d3d;
    }
  }
  .border {
    border-bottom: 1px solid #eeeeee;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 16px;
  }
  .cell {
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 32px;
    overflow: hidden;
    color: @dark-font-color;
    font-size: 28px;
  }
  .btnbox {
    position: relative;
    height: 40px;
    .btn {
      position: absolute;
      right: 0;
      margin-right: 32px;
    }
  }
  .cell_title,
  .cell_value {
    flex: 1 1 auto;
  }
  .cell_value {
    overflow: hidden;
    text-align: right;
    color: #3d3d3d !important;
  }
}
.reason-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px 32px;
  margin: 40px 0;
  .reason-item {
    width: 25%;
    // width: 160px;
    height: 52px;
    margin: 12px 22px;
    line-height: 52px;
    font-size: 28px;
    color: #666;
    text-align: center;
    background: #f2f2f2;

    &.active {
      color: #fff;
      background: #1c9cf6;
    }
  }
}

.red-color {
  color: #ff2c23;
}
.mt10 {
  margin-top: 10px;
}
.status {
  padding: 6px 18px;
  height: 48px;
  font-size: 26px;
  line-height: 48px;
  text-align: center;
  border-radius: 24px;
  white-space: nowrap;
}
.blue {
  border: 1px solid #1c9cf6;
  color: #1c9cf6;
}
.red {
  border: 1px solid #dc3030;
  color: #dc3030;
}
.grey {
  border: 1px solid #999999;
  color: #999999;
}
</style>
