/*
 * @Author: your name
 * @Date: 2021-03-05 14:41:54
 * @LastEditTime: 2021-03-05 14:42:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\common\mutation-types.js
 */
export const SET_USER = 'SET_USER'

// 环信相关
export const UPDATE_MESSAGE_LIST = 'UPDATE_MESSAGE_LIST'

export const SET_CHAT_ID = 'SET_CHAT_ID'

export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS'

export const UPDATE_MESSAGE_STATUS = 'UPDATE_MESSAGE_STATUS'

export const UPDATE_MESSAGE_MID = 'UPDATE_MESSAGE_MID'

export const UPDATE_READ_STATUS = 'UPDATE_READ_STATUS'

export const SET_FINISH_STATUS = 'SET_FINISH_STATUS'

export const SET_MESSAGE_DICT = 'SET_MESSAGE_DICT'

export const SET_WAITING_SEND_MSG_LIST = 'SET_WAITING_SEND_MSG_LIST'

export const SET_CURRENT_MESSAGE_LIST = 'SET_CURRENT_MESSAGE_LIST'

export const SET_WAITING_SEND_MESSAGE_DICT = 'SET_WAITING_SEND_MESSAGE_DICT'

export const SET_CONFERENCE_CONFIG = 'SET_CONFERENCE_CONFIG'

export const SET_WEAK_NETWORK = 'SET_WEAK_NETWORK'

export const SET_MEDIA_ERROR = 'SET_MEDIA_ERROR'

export const SET_MEMBER_LIST = 'SET_MEMBER_LIST'

// 此处默认chat的module名称
export const getMutationType = (key, moduleName = 'chat') => `${moduleName}/${key}`
