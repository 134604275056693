<template>
  <div>
    <div v-for="item in data" :key="item.id" class="card-item" @click="routeTo(item)">
      <div class="docbox">
        <div class="photo">
          <default-image is-avatar round />
        </div>
        <div class="mid">
          <div class="dark-color">
            <span class="name">{{ item.ysxm }}</span>&nbsp; <span class="desc">{{ item.zc }}</span>
          </div>
          <div class="desc mt10">
            {{ item.ksmc }}
          </div>
        </div>
        <div class="status blue">
          {{ type == "1" ? item.yydztmc : item.yyztmc }}
        </div>
      </div>
      <div class="border" />
      <div class="cell">
        <div class="cell_title">
          就诊人：
        </div>
        <div class="cell_value">
          {{ item.jzrxm }} {{ item.jzrxbmc }} {{ item.age }}岁
        </div>
      </div>
      <div class="cell">
        <div class="cell_title">
          预约日期：
        </div>
        <div class="cell_value">
          {{ item.hyrq }}
        </div>
      </div>
      <div class="cell">
        <div class="cell_title">
          预约时段：
        </div>
        <div class="cell_value">
          {{ item.fskssj }}-{{ item.fsjssj }}
        </div>
      </div>
      <div class="cell">
        <div class="cell_title">
          挂号费用：
        </div>
        <div class="cell_price">
          ¥ {{ item.ghf }}
        </div>
      </div>
      <!-- 0已预约挂号 1已支付 -->
      <div v-if="item.yyzt == 0">
        <div class="border" />
        <div v-if="item.canPay" class="btnbox">
          <div class="btn_left status grey" @click.stop="cancelsChoose(item,'1')">
            取消
          </div>
          <div class="btn status red">
            去支付
          </div>
        </div>
        <div v-else class="btnbox">
          <div class="btn status grey" @click.stop="cancelsChoose(item, '1')">
            取消
          </div>
        </div>
      </div>
      <div v-if="item.yyzt == 1">
        <div class="border" />
        <div class="btnbox">
          <div class="btn status blue" @click.stop="cancelsChoose(item, '2')">
            退款
          </div>
        </div>
      </div>
    </div>
    <van-dialog v-model="show" :title="'请选择原因'" :loading="loading" show-confirm-button show-cancel-button theme="round-button" confirm-button-text="确定" cancel-button-text="取消" confirm-button-color="#3A8AE5" cancel-button-color="#DDDDDD" :before-close="clickConfirm">
      <div class="reason-list">
        <div v-for="(v, index) in cancelContent" :id="v.id" :key="v.id" class="reason-item" :data-name="v.name" :data-current="index" :class="tabIndex == index ? 'active' : ''" @click="cancelChoose(v, index)">
          {{ v.name }}
        </div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import { gjcModel } from "../../api/gjcApi"

export default {
  name: "GjcAppointmentItem",
  props: {
    data: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: ""
    }
    //type=2 挂号，1预约
  },
  data() {
    return {
      seleteItem:{},
      registerId: null,
      tabIndex: null,
      show: false,
      reason: "",
      loading: false,
      reasonType: "", //类型: '1':取消 2:退款
      cancelContent: [
        {
          name: "临时有事",
          id: "nsys"
        },
        {
          name: "重复预约",
          id: "cfyy"
        },
        {
          name: "不想去了",
          id: "bxql"
        },
        {
          name: "约错时间",
          id: "ycsj"
        },
        {
          name: "约错科室",
          id: "ycks"
        },
        {
          name: "约错医生",
          id: "ycys"
        }
      ]
    }
  },
  methods: {
    routeTo(item) {
      this.$router.push({
          path: '/appointDetail',
          query: {
              id: item.id
          }
      })
    },
    /**
     * 取消选择预约项
     * @param {Object} item - 预约项对象
     * @param {string} type - 类型标识，'2' 表示订单信息ID，其他表示预约项ID
     * @description 根据类型设置显示状态、注册ID和原因类型
     */
    cancelsChoose(item, type) {
      this.seleteItem = item
      this.show = true
      this.registerId = type ==='2'? item.orderInfoId :item.id
      this.reasonType = type
    },
    cancelChoose(item, index) {
      this.reason = item.name
      this.tabIndex = index
    },
    clickConfirm(action, done) {
      if (action === "confirm") {
        if (this.reason == "") {
          this.$toast("请选择原因!")
          return done(false)
        }
        this.loading = true
        if (this.reasonType == "1"){
          gjcModel.getCancelRegisterData({ qxyy: this.reason, appointRecordId: this.registerId }).then(() => {
            this.$toast("取消成功!")
            this.$emit("refresh")
          }).finally(() => {
            this.loading = false
            this.reason = ""
            this.tabIndex = null
            done()
          })
        }else{
          if (this.seleteItem.fklx =='1'){ //直费
            gjcModel.getWXRefundData({ refundReason: this.reason, orderId: this.registerId, orderType:'13' }).then(() => {
              this.$toast("退款成功!")
              this.$emit("refresh")
            }).finally(() => {
              this.loading = false
              this.reason = ""
              this.tabIndex = null
              done()
            })
          }else if (this.seleteItem.fklx =='2'){ //医保
            gjcModel.getYbRefundData({ refReason: this.reason, orderId: this.registerId}).then(() => {
              this.$toast("退款成功!")
              this.$emit("refresh")
            }).finally(() => {
              this.loading = false
              this.reason = ""
              this.tabIndex = null
              done()
            })
          }
        }
      } else {
        this.reason = ""
        this.tabIndex = null
        done()
      }
    }
  }
}
</script>
<style scoped  lang='less'>
@import "../../assets/styles/variable.less";
.card-item {
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  margin-bottom: 32px;
  padding-bottom: 32px;

  .docbox {
    display: flex;
    padding: 32px;
    justify-content: space-between;
    box-sizing: border-box;
    .photo {
      width: 92px;
      flex: 0 0 92px;
      height: 92px;
      margin-right: 16px;
    }
    .mid {
      flex: 1;
      line-height: 40px;
    }
    .name {
      font-size: 32px;
      color: @dark-font-color;
      font-weight: bold;
      margin-right: 10px;
    }
    .desc {
      font-size: 28px;
      color: #3d3d3d;
    }
  }
  .border {
    border-bottom: 1px solid #eeeeee;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 16px;
  }
  .cell {
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 32px;
    overflow: hidden;
    color: @dark-font-color;
    font-size: 28px;
  }
  .btnbox {
    display: flex;
    position: relative;
    height: 40px;
    .btn_left {
      position: absolute;
      right: 140px;
      width: 80px;
      margin-right: 32px;
      border-radius: 40px;

    }
    .btn {
      position: absolute;
      right: 0;
      width: 80px;
      margin-right: 32px;
      border-radius: 40px;

    }
  }
  .cell_title,
  .cell_value {
    flex: 1 1 auto;
  }
  .cell_value {
    overflow: hidden;
    text-align: right;
    color: #3d3d3d !important;
  }
  .cell_price{
    overflow: hidden;
    text-align: right;
    color: #ff2c23 !important;
  }
}
.reason-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px 32px;
  margin: 40px 0;
  .reason-item {
    width: 25%;
    // width: 160px;
    height: 52px;
    margin: 12px 22px;
    line-height: 52px;
    font-size: 28px;
    color: #666;
    text-align: center;
    background: #f2f2f2;

    &.active {
      color: #fff;
      background: #1c9cf6;
    }
  }
}

.red-color {
  color: #ff2c23;
}
.mt10 {
  margin-top: 10px;
}
.status {
  padding: 6px 18px;
  height: 48px;
  font-size: 26px;
  line-height: 48px;
  text-align: center;
  border-radius: 24px;
  white-space: nowrap;
}
.blue {
  border: 1px solid #1c9cf6;
  color: #1c9cf6;
}
.red {
  border: 1px solid #dc3030;
  color: #dc3030;
}
.grey {
  border: 1px solid #999999;
  color: #999999;
}
</style>
