/*
 * @Author: your name
 * @Date: 2021-03-04 10:04:16
 * @LastEditTime: 2024-11-14 13:53:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\main.js
 */
import Vue from 'vue'
import VConsole from 'vconsole'
import App from './App.vue'
import router from './router'
import store from './store'
import './router/permission'
import './vant'
// 引入全部样式	
import 'vant/lib/index.less'
import './blueCross-ui'
import 'lib-flexible'
import './assets/styles/index.less'
if (process.env.VUE_APP_ENV === 'Ali') {
  new VConsole({ theme: 'light' })
}
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
