/*
 * @Author: 张茨一飞
 * @Date: 2024-08-26 13:23:16
 * @LastEditTime: 2024-10-11 13:18:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\router\gjc.js
 */


export const gjcRoutes = [
  {
    path: '/seleteDept',
    name: 'seleteDept',
    meta: {
      title: '预约挂号'
    },
    component: () => import('../views/gjc/sever/seleteDept.vue')
  },
  {
    path: '/deptSource',
    name: 'deptSource',
    meta: {
      title: '号源列表'
    },
    component: () => import('../views/gjc/sever/deptSource.vue')
  },
  {
    path: '/gjcRegister',
    name: 'gjcRegister',
    meta: {
      title: '预约确定'
    },
    component: () => import('../views/gjc/sever/gjcRegister.vue')
  },
  {
    path: '/appointDetail',
    name: 'appointDetail',
    meta: {
      title: '预约详情'
    },
    component: () => import('../views/gjc/sever/appointDetail.vue')
  },
  {
    path: '/orderPay',
    name: 'orderPay',
    meta: {
      title: '订单支付',
      customBackEvent: true,
      showBackBtn: true,
      bgClass: 'white-bg'
    },
    component: () => import('../views/gjc/sever/orderPay.vue')
  },
  {
    path: '/docMain',
    name: 'docMain',
    meta: {
      title: '医生主页'
    },
    component: () => import('../views/gjc/sever/docMain.vue')
  },
  {
    path: '/appointList',
    name: 'appointList',
    meta: {
      title: '我的预约'
    },
    component: () => import('../views/gjc/sever/appointList.vue')
  },
  {
    path: '/reportList',
    name: 'reportList',
    meta: {
      title: '报告查询'
    },
    component: () => import('../views/gjc/sever/reportList.vue')
  },
  {
    path: '/patientList',
    name: 'patientList',
    meta: {
      title: '就诊人管理'
    },
    component: () => import('../views/gjc/patient/patientList.vue')
  },
  {
    path: '/patientAdd',
    name: 'patientAdd',
    meta: {
      title: '添加就诊人'
    },
    component: () => import('../views/gjc/patient/patientAdd.vue')
  },
  {
    path: '/patientEdit',
    name: 'patientEdit',
    meta: {
      title: '编辑就诊人'
    },
    component: () => import('../views/gjc/patient/patientEdit.vue')
  },
  {
    path: '/signIn',
    name: 'signIn',
    meta: {
      title: '签到排队'
    },
    component: () => import('../views/gjc/signIn/signIn.vue')
  },
  {
    path: '/signInList',
    name: 'signInList',
    meta: {
      title: '签到排队'
    },
    component: () => import('../views/gjc/signIn/signInList.vue')
  },
  {
    path: '/payFees',
    name: 'payFees',
    meta: {
      title: '在线缴费',
      customBackEvent: true,
      showBackBtn: true,
      bgClass: 'white-bg'
    },
    component: () => import('../views/gjc/sever/payFees.vue')
  },
  {
    path: '/registerPayList',
    name: 'registerPayList',
    meta: {
      title: '缴费记录'
    },
    component: () => import('../views/gjc/sever/registerPayList.vue')
  },
  {
    path: '/registerDetail',
    name: 'registerDetail',
    meta: {
      title: '缴费详情'
    },
    component: () => import('../views/gjc/sever/registerDetail.vue')
  },

  {
    path: '/gjcPrescription',
    name: 'gjcPrescription',
    meta: {
      title: '电子处方'
    },
    component: () => import('../views/gjc/sever/prescription.vue')
  },
  {
    path: '/gjcPrescriptionDetail',
    name: 'gjcPrescriptionDetail',
    meta: {
      title: '处方详情'
    },
    component: () => import('../views/gjc/sever/prescriptionDetail.vue')
  },
  {
    path: '/costList',
    name: 'costList',
    meta: {
      title: '费用明细'
    },
    component: () => import('../views/gjc/sever/costList.vue')
  },
  {
    path: '/costDetail',
    name: 'costDetail',
    meta: {
      title: '费用详情'
    },
    component: () => import('../views/gjc/sever/costDetail.vue')
  },
  {
    path: '/dayList',
    name: 'dayList',
    meta: {
      title: '一日清单'
    },
    component: () => import('../views/gjc/sever/dayList.vue')
  },
  {
    path: '/gjcHosp',
    name: 'gjcHosp',
    meta: {
      title: '医院介绍'
    },
    component: () => import('../views/gjc/hosp/gjcHosp.vue')
  },
  {
    path: '/priceList',
    name: 'priceList',
    meta: {
      title: '价格公示'
    },
    component: () => import('../views/gjc/hosp/priceList.vue')
  },
  {
    path: '/gjcHospPath',
    name: 'gjcHospPath',
    meta: {
      title: '来院路线'
    },
    component: () => import('../views/gjc/hosp/gjcHospPath.vue')
  },
  {
    path: '/deptList',
    name: 'deptList',
    meta: {
      title: '科室选择'
    },
    component: () => import('../views/gjc/hosp/deptList.vue')
  },
  {
    path: '/deptDetail',
    name: 'deptDetail',
    meta: {
      title: '科室介绍'
    },
    component: () => import('../views/gjc/hosp/deptDetail.vue')
  },
  {
    path: '/docDetail',
    name: 'docDetail',
    meta: {
      title: '医生详情'
    },
    component: () => import('../views/gjc/hosp/docDetail.vue')
  },
  {
    path: '/inNav',
    name: 'inNav',
    meta: {
      title: '院内导航'
    },
    component: () => import('../views/gjc/hosp/inNav.vue')
  },
  {
    path: '/msgList',
    name: 'msgList',
    meta: {
      title: '消息列表',
      showRightBtn: true,// 是否显示右边按钮
      rightBtnText: '一键全读',// 右边按钮文字
      rightBtnActive: false,// 右边按钮高亮
      emitEventByActive: true  // 是否只在右侧按钮是active时派发事件
    },
    component: () => import('../views/gjc/msg/msgList.vue')
  },
  {
    path: '/msgDetail',
    name: 'msgDetail',
    meta: {
      title: '消息详情'
    },
    component: () => import('../views/gjc/msg/msgDetail.vue')
  },
  {
    path: '/ybPay',
    name: 'ybPay',
    meta: {
      title: '确认支付'
    },
    component: () => import('../views/gjc/pay/ybPay.vue')
  }, {
    path: '/paySuccess',
    name: 'paySuccess',
    meta: {
      title: '支付结果',
      showBackBtn: true,
      bgClass: 'white-bg',
      customBackEvent: true
    },
    component: () => import('../views/gjc/pay/paySuccess.vue')
  },
  {
    path: '/hosptialCostDetail',
    name: 'hosptialCostDetail',
    meta: {
      title: '住院缴费',
      showBackBtn: true,
      bgClass: 'white-bg',
      customBackEvent: true
    },
    component: () => import('../views/gjc/sever/hosptialCostDetail.vue')
  }

]
